import "./NotFound.css";
import NavBar from "../../features/nav_bar/NavBar";
import { Link } from "react-router-dom";
import Footer from "../home/Footer";
import { useTranslation } from "react-i18next";

function NotFound() {
  const { t, i18n } = useTranslation()
  
  return (
    <div className="App">
      <header className="App-header">
        <NavBar />
      </header>
      <body>
        <div className="not-found-container">
          <h1>{t('not_found_title')}</h1>
          <p>{t('not_found_text')}</p>
          <Link to="/">
            <button className="go-back-button">{t('not_found_action')}</button>
          </Link>
        </div>
        <footer id="download" className="footer">
          <Footer />
        </footer>
      </body>
    </div>
  );
}

export default NotFound;
