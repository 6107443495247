import React, { memo, useContext, useState } from 'react';
import './Transaction.css';
import bag_icon from './svg_icons/bag_icon.svg';
import bag_person_icon from './svg_icons/bag_person_icon.svg';
import expense_done from './svg_icons/expense_done.svg';
import expense_icon from './svg_icons/expense_icon.svg';
import expense_error from './svg_icons/expense_error.svg';
import image_icon from './svg_icons/image_icon.svg';
import { getDocs, collection, doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { formatDate } from '../../features/utils/DateFormatter';
import { formatAmount } from '../../features/utils/CurrencyFormatter';
import { ParticipantsContext } from './GroupData';
import { useTranslation } from 'react-i18next'

function Transaction({ item, currencyData, is_group_view }: { item: any, currencyData: any, is_group_view: boolean }) {
    const { t, i18n } = useTranslation()

    const participants: { [key: string]: any } = useContext(ParticipantsContext)
    const sign = item.type == "expense" ? "-" : "+"

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = () => {
        setModalIsOpen(true);
    }

    const closeModal = () => {
        setModalIsOpen(false);
    }


    function getStatusClass() {
        if (item.type == "income") return "paid"
        if (item.is_done) return "paid"
        if (item.date.toDate() < new Date()) return "unpaid"
        return "pending"
    }

    function getStatus() {
        if (item.type == "income") return t('transaction_status_received')
        if (item.is_done) return t('transaction_status_paid')
        if (item.date.toDate() < new Date()) return t('transaction_status_unpaid')
        return t('transaction_status_pending')
    }

    function getName() {
        var name = item.name
        if (name != null && name != "") return name
        name = item.income_source
        if (name != null && name != "") return name
        if (item.participant_ids.length > 0 && item.type == "income") {
            if (is_group_view) {
            const id: string = item.participant_ids[0]
            return participants[id].name
            } else {
                return "Income"
            }
        }
        return ""
    }

    function getDisplayName() {
        const name = getName()
        if (name.length > 0) return name
        return (item.type == "expense") ? t('history_generic_expense') : t('history_generic_income')
    }

    function getDisplaySubtitle() {
        if (item.type == "expense") {
            if (item.count > 1 || (is_group_view && item.count > 0)) {
                return `${t('create_expense_info_count')} ${item.count}`
            }
            return (is_group_view) ? t('history_no_participants') : null
        } else {
            if (!is_group_view) return null
            const text = item.count == 1 ? t('history_income_description_participant') : t('history_income_description_generic')
            if (item.income_source != null && item.income_source.length > 0) {
                if (item.participant_ids.length > 0) {
                    const id: string = item.participant_ids[0]
                    return participants[id].name
                } else {
                    return text
                }
            } else {
                return text
            }
        }
    }

    function getIcon() {
        if (item.type == "expense") {
            if (item.is_done) {
                return expense_done
            } else if (item.date.toDate() < new Date()) {
                return expense_error
            }
            return expense_icon
        } 
        if (item.participant_ids.length > 0) {
            return bag_person_icon
        }
        return bag_icon
    }

    return (
        <>        
            {modalIsOpen  && (
            <div onClick={closeModal} className='modal'>
                <img src={item.image_url} className="modal-image"/> 
                <button type='button' onClick={closeModal} className="close-button button-common">X</button>
            </div>
            )}
            <div  className='transaction'>
                <div className='space'></div>
                <div className='icon-state'><span><img src={getIcon()} /></span></div>
                <div className='name'>
                    <div>
                        <div className='title'>{getDisplayName()}</div>
                        <div className='subtitle'>{getDisplaySubtitle()}</div>
                    </div>
                    {item.image_url && (<button type='button' onClick={openModal} className="expense-image-button button-common" ><img src={image_icon} /></button>)}
                </div>
                <div className='date'>{formatDate(item.date.toDate())}</div>
                <div className='amount'>
                    <div className={'title ' + (item.type == "income" ? 'positive' : 'expense')}>{sign + formatAmount(item.amount, currencyData)}</div>
                    {item.add_amount > 0 &&
                        <div className='subtitle'>{sign + formatAmount(item.add_amount, currencyData)}</div>
                    }
                </div>
                <div className='status-col'>
                    <div className='round'>
                        <div className={'status ' + getStatusClass()}>
                            {getStatus()}
                        </div>
                    </div>
                </div>
                <div className='space'></div>
            </div>
            <hr />
        </>

    );
}

export default memo(Transaction);