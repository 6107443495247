export const cookiesStorage = {
    get: function (key) {
        var cookies = document.cookie.split(';');
        var cookiesObj = {};
        cookies.forEach(function (cookie) {
            var cookieArr = cookie.split('=');
            cookiesObj[cookieArr[0].trim()] = cookieArr[1];
        });
        return cookiesObj[key];
    },
    set: function (key, value) {
        document.cookie = key + '=' + value;
    },
    remove: function (key) {
        document.cookie = key + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    },
    cookiesAccepted: function() {
        return cookiesStorage.get("cookiesAccepted")
    },
    setCookiesAccepted: function(value) {
        cookiesStorage.set("cookiesAccepted", value)
    }
}
