
import es_appstore_badge from './icons/appstore/es_appstore_badge.svg'
import fr_appstore_badge from './icons/appstore/fr_appstore_badge.svg'
import pt_appstore_badge from './icons/appstore/pt_appstore_badge.svg'
import ru_appstore_badge from './icons/appstore/ru_appstore_badge.svg'
import us_appstore_badge from './icons/appstore/us_appstore_badge.svg'

export function getAppstoreBadge(language: string) {
    function image() {
        switch (language) {
            case "ru":
                return ru_appstore_badge
            case "fr":
                return fr_appstore_badge
            case "es":
                return es_appstore_badge
            case "pt":
                return pt_appstore_badge
            default:
                return us_appstore_badge
        }
    }

    return (<a href="https://apps.apple.com/app/groupwallet/id6446291419" target="_blank" rel="noopener noreferrer">
        <img src={image()} />
    </a>)
}

export function getGoogleBadge(language: string) {
    function suffix() {
        switch (language) {
            case "ru": return "ru"
            case "es": return "es"
            case "pt": return "pt"
            default: return "en"
        }
    }
    var url = "https://play.google.com/intl/"
    url += suffix().toLowerCase() + "_" + suffix().toUpperCase()
    url += "/badges/static/images/badges/" + suffix() + "_badge_web_generic.png"
    return (
        <a href='https://play.google.com/store/apps/details?id=com.group_wallet.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target="_blank" rel="noopener noreferrer">
            <img alt='Get it on Google Play' src={url} />
        </a>
    )
}

export function getRuStoreBadge(){
    return (
        <a href='https://www.rustore.ru/catalog/app/com.group_wallet.app' target="_blank" rel="noopener noreferrer">
            <img alt='Get it on RuStore' src="https://www.rustore.ru/help/icons/logo-color-dark.svg"/>
        </a>
    )
}

export { }