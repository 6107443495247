import React from 'react';
import './Body.css';
import { useTranslation } from 'react-i18next'
import { getAppstoreBadge, getGoogleBadge, getRuStoreBadge } from './utils';

import main_en from '../../images/main_en.png';
import main_ru from '../../images/main_ru.png';
import main_pt from '../../images/main_pt.png';
import main_es from '../../images/main_es.png';

function Body() {
    const { t, i18n } = useTranslation()

    function image() {
        switch (i18n.language) {
            case "ru":
                return main_ru
            case "es":
                return main_es
            case "pt":
                return main_pt
            default:
                return main_en
        }
    }

    return (
        <section className='slider__selection'>
            <div className='caorusel__inner'>
                <div className='caorusel__item'>
                    <div className='container'>
                        <div className='container__row'>
                            <div className='col__1'>
                                <div className='detail__box'>
                                    <h1>
                                        {t('landing_title')}
                                    </h1>
                                    <p>
                                    {t('landing_description')}
                                    </p>
                                </div>
                                <div className='buttons'>
                                <div className='ios_badge'>
                                    {getAppstoreBadge(i18n.language)}
                                </div>
                                <div className={i18n.language === 'ru' ? "rustore_badge" : "google_badge"}>
                                {i18n.language === 'ru'
                                    ? getRuStoreBadge()
                                    : getGoogleBadge(i18n.language)}
                                </div>
                            </div>
                            </div>
                            <div className='col__2'>
                                <div className='image__box'>
                                    <img src={image()} alt='main'></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Body;