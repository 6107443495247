
export function formatDate(date) {
  const year = date.getFullYear()
  const day = date.getDate()
  const monthName = date.toLocaleString('default', { month: 'short' })
  return `${day} ${monthName} ${year}`
}

export function formatDayMonth(date) {
  const day = date.getDate()
  const monthName = date.toLocaleString('default', { month: 'long' })
  return `${day} ${monthName}`
}


export function formatMonthYear(date) {
  const year = date.getFullYear()
  const monthName = date.toLocaleString('default', { month: 'long' })
  return `${monthName} ${year}`
}