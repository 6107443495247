import { useTranslation } from "react-i18next";
import { getAppstoreBadge, getGoogleBadge, getRuStoreBadge } from "../../home/utils";

type InstructionStepProps = {
  step: {
    title: string;
    text: string;
    groupId?: string;
    badges?: boolean;
    src?: string;
    alt?: string;
  };
  onImageClick: (src: string) => void;
};

const InstructionsSteps = ({
  groupId,
  onClick,
}: {
  groupId: string;
  onClick: (src: string) => void;
}) => {
  const { t, i18n } = useTranslation();

  function suffix() {
    switch (i18n.language) {
      case "ru":
        return "ru";
      case "es":
        return "es";
      case "pt":
        return "pt";
      default:
        return "en";
    }
  }

  function instructionSteps() {
    return [
      {
        title: t('instructions_step_1_title'),
        text: t('instructions_step_1_description'),
        badges: true,
      },
      {
        title: t('instructions_step_2_title'),
        text: t('instructions_step_2_description'),
        src: require(`../../../images/step_1_${suffix()}.jpg`),
      },
      {
        title: t('instructions_step_3_title'),
        text: t('instructions_step_3_description'),
        groupId: groupId,
        src: require(`../../../images/step_2_${suffix()}.jpg`),
      },
      {
        title: t('instructions_step_4_title'),
        text: t('instructions_step_4_description'),
        src: require(`../../../images/step_3_${suffix()}.jpg`),
      },
      {
        title: t('instructions_step_5_title'),
        text: t('instructions_step_5_description'),
        src: require(`../../../images/step_4_${suffix()}.jpg`),
      },
      {
        title: t('instructions_step_6_title'),
        text: t('instructions_step_6_description'),
        src: require(`../../../images/step_5_${suffix()}.jpg`),
      },
    ];
  }

  const InstructionStep = ({ step, onImageClick }: InstructionStepProps) => (
    <li>
      <span className="step-title">{step.title}</span>
      <p className="instruction-text">
        {step.text}
        {step.groupId && <span className="highlight">{step.groupId}</span>}
      </p>
      {step.badges && (
        <div className="flex-center badges">
          <div className="ios">{getAppstoreBadge(i18n.language)}</div>
          <div className={i18n.language === "ru" ? "rustore" : "google"}>
            {i18n.language === "ru"
              ? getRuStoreBadge()
              : getGoogleBadge(i18n.language)}
          </div>
        </div>
      )}
      {step.src && (
        <div className="flex-center instruction-image-container">
          <img
            src={step.src}
            className="instruction-image"
            alt={step.alt}
            onClick={() => onImageClick(step.src!)}
          />
        </div>
      )}
    </li>
  );

  return (
    <>
      {instructionSteps().map((step, index) => (
        <InstructionStep key={index} step={step} onImageClick={onClick} />
      ))}
    </>
  );
};

export default InstructionsSteps;
