import React, { useState } from 'react';
import './Features_body.css';
import { useTranslation } from 'react-i18next'


function Features_body() {
    const { t, i18n } = useTranslation()

    function suffix() {
        switch (i18n.language) {
            case "ru": return "ru"
            case "es": return "es"
            case "pt": return "pt"
            default: return "en"
        }
    }

    function elements() {
        return [
            {
                'image': require('../../images/_1_' + suffix() + '.png'),
                'title': t('landing_feature1_title'),
                'description': t('landing_feature1_description'),
            },
            {
                'image': require('../../images/_2_' + suffix() + '.png'),
                'title': t('landing_feature2_title'),
                'description': t('landing_feature2_description'),
            },
            {
                'image': require('../../images/_3_' + suffix() + '.png'),
                'title': t('landing_feature3_title'),
                'description': t('landing_feature3_description'),
            },
            {
                'image': require('../../images/_4_' + suffix() + '.png'),
                'title': t('landing_feature4_title'),
                'description': t('landing_feature4_description'),
            },
            {
                'image': require('../../images/_5_' + suffix() + '.png'),
                'title': t('landing_feature5_title'),
                'description': t('landing_feature5_description'),
            },
            {
                'image': require('../../images/_6_' + suffix() + '.png'),
                'title': t('landing_feature6_title'),
                'description': t('landing_feature6_description'),
                'type': 'center'
            }
        ]
    }

    return (
        <section className='service__section'>
            <div className='container__fluid'>
                <div className='heading__container'>
                    <h1>{t('generic_features')}</h1>
                </div>
                {elements().map((item, index) =>
                    <div className='service__container'>
                        <div className={'row__container' + (index % 2 == 1 ? ' row_odd' : '') + (item.type == 'center' ? ' center' : '')}>
                            <div className='feature__image'>
                                <img src={item.image}></img>
                            </div>
                            <div className='feature__texts'>
                                <div className='title'>
                                    {item.title}
                                </div>
                                <div className='description'>
                                    {item.description}
                                </div>
                            </div>
                        </div>
                    </div>
                )}

            </div>
        </section>
    );
}

export default Features_body;