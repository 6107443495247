import exp from "constants";
import './NavBar.scss';
import logo from './logo.png';
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import { HashLink as Link } from 'react-router-hash-link';

function NavBar({is_group=false}) {
    const { t, i18n } = useTranslation()

    function contactItem() {
        return (<li><NavLink className="menu__item" to="contact" onClick={(e) => {
            e.preventDefault()
            window.location.href = 'mailto:support@group-wallet.com'
        }}>{t('navbar_contact')}</NavLink></li>)
    }

    return (
        <header>
            <div className="logotype">
                <a><img src={logo} width="200" /></a>
                <span className="red"></span>
            </div>
            <nav>
                <input id="menu__toggle" type="checkbox" />
                <label className="menu__btn" htmlFor="menu__toggle">
                    <span></span>
                </label>
                {
                    (is_group) ? (
                        <ul className="menu__box">
                            {contactItem()}
                        </ul>
                    ) : (
                        <ul className="menu__box">
                            <li><NavLink className="menu__item" to="/"><span className="red">{t('generic_home')}</span></NavLink></li>
                            <li><Link className="menu__item" to='/#features'>{t('generic_features')}</Link></li>
                            <li><Link className="menu__item" to='/#download'>{t('generic_download')}</Link></li>
                            {contactItem()}
                        </ul>
                    )
                }
            </nav>
        </header>

    );
};


export default NavBar