// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBa7h-XNu5Y0pMUQcNK3XnXdIRobvH8mr4",
  authDomain: "groupwallet-73b11.firebaseapp.com",
  projectId: "groupwallet-73b11",
  storageBucket: "groupwallet-73b11.appspot.com",
  messagingSenderId: "810953243456",
  appId: "1:810953243456:web:c07cec3ea2b8e22b3318a5",
  measurementId: "G-ETRRZD25PJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);
// connectFirestoreEmulator(db, '127.0.0.1', 8020);
