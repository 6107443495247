import React from 'react';
import './Home.css';
import NavBar from '../../features/nav_bar/NavBar';
import Body from '../home/Body';
import Features_body from './Features_body';
import Footer from './Footer';

function Home() {
  return (
    <div className="App">
      <header className="App-header">
      <NavBar />
      </header>
      <body>
        <div className='Body__main'>
        <Body />
        </div>
        <div id='features' className='Features__main'>
          <Features_body />
        </div>
        <footer id='download' className='footer'>
          <Footer />
        </footer>
      </body>
    </div>
  );
}

export default Home;
