import React from 'react';
import './Footer.css';
import logo from '../../features/nav_bar/logo.png';
import envelope from '../../images/envelope-white.png';
import { getAppstoreBadge, getGoogleBadge, getRuStoreBadge } from './utils';
import { useTranslation } from 'react-i18next';

function Footer() {
    const { t, i18n } = useTranslation()

    return (
        <div className='footer__bg'>
            <section className='info__section'>
                <div className='container__info'>
                    <div className='row__info'>
                        <div className='download_text'>
                            <h2>{t('landing_footer_title')}</h2>
                            <div className='buttons'>
                                <div className='ios_badge'>
                                    {getAppstoreBadge(i18n.language)}
                                </div>
                                <div className={i18n.language === 'ru' ? "rustore_badge" : "google_badge"}>
                                {i18n.language === 'ru'
                                    ? getRuStoreBadge()
                                    : getGoogleBadge(i18n.language)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row__info'>
                        <div className='col__info'>
                            <div className='info__logo'>
                                <img src={logo} alt="" width="200" />
                            </div>
                        </div>
                        <div className='col__info'>
                            <div className='info__links'>
                                <ul className=''>
                                    <li className='active'><a href="#">{t('generic_home')}</a></li>
                                    <li><a href='/Privacy-policy'>{t('generic_privacy_policy')}</a></li>
                                    <li><a href='/Terms-and-conditions'>{t('generic_terms')}</a></li>
                                    <li><a href="mailto:support@group-wallet.com">{t('generic_contact_us')}</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className='col__info'>
                            <div className='info__contact' id='contact'>
                                <a href="mailto:support@group-wallet.com">
                                    <div className="img__box">
                                        <img src={envelope} width="18px" alt="" />
                                    </div><p>support@group-wallet.com</p></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='container__fluid__footer'>
                <div className='container__inner'>
                    <p>© <span id='display__year'>2023</span>{' ' + t('landing_footer_rights_reserved') + ' Aleksandr Severianov'}</p>
                </div>
            </section>
        </div>
    )
};
export default Footer;