import './CookiesBanner.css'

import {cookiesStorage} from './../../features/utils/CookiesStorage'
import { useState } from 'react';
import { useTranslation } from 'react-i18next'

function CookiesBanner({ onAccepted }: { onAccepted: () => void }) {
    const { t, i18n } = useTranslation()

    const [cookiesAccepted, setCookiesAccepted] = useState<boolean>(cookiesStorage.cookiesAccepted)

    return (
             (cookiesAccepted != null) ? null : (
            <div className='cookies-banner'>
                <div className='content'>
                    <div className='cookies-title'>
                        {t('cookies_banner_title')}
                        <br />
                        <a href="/cookies-policy">{t('generic_read_more')}</a>
                    </div>
                    <div className='actions-row'>
                        <button className='cookies-btn reject' onClick={(e) => {
                            cookiesStorage.setCookiesAccepted(false)
                            setCookiesAccepted(false)
                        }}>{t('generic_actions_reject')}</button>
                        <button className='cookies-btn' onClick={(e) => {
                            cookiesStorage.setCookiesAccepted(true)
                            setCookiesAccepted(true)
                            onAccepted()
                        }}>{t('generic_actions_accept')}</button>
                    </div>
                </div>
            </div>)
    )
}

export default CookiesBanner;