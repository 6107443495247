import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';
import Home from './pages/home/Home';
import Group from './pages/group/Group';
import PrivacyPolicy from './pages/home/Terms/PrivacyPolicy';
import TermsAndCondition from './pages/home/Terms/TermsAndCondition';
import Footer from './pages/home/Footer';
import CookiesPolicy from './pages/home/Terms/CookiesPolicy';
import './i18n';
import Instructions from './pages/group/instructions/Instructions';
import NotFound from './pages/notfound/NotFound';

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path='/g/:groupId' element={<Group />}/>
        <Route path='/g/:groupId/i' element={<Instructions />} />
        <Route path='/Privacy-policy' element={<PrivacyPolicy />}/>
        <Route path='/Terms-and-conditions' element={<TermsAndCondition />}/>
        <Route path='/cookies-policy' element={<CookiesPolicy />}/>
        <Route path='*' element={<NotFound />} />
      </Routes>
      </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();
