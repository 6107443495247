import { collection, doc, getDoc, getDocs, orderBy, query, where } from "firebase/firestore";
import { useState, useEffect, createContext } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import { formatAmount } from "../../features/utils/CurrencyFormatter";
import arrow from './svg_icons/arrow-down.svg';
import bell from './svg_icons/bell.svg';
import Transaction from "./Transaction";
import NavBar from "../../features/nav_bar/NavBar";
import { formatDayMonth, formatMonthYear } from "../../features/utils/DateFormatter";
import CookiesBanner from "./CookiesBanner";
import { cookiesStorage } from "../../features/utils/CookiesStorage";
import { useTranslation } from 'react-i18next'
import { Helmet } from "react-helmet";
import { useNavigate } from 'react-router-dom';

export const ParticipantsContext = createContext({})

function GroupData({ groupData }: { groupData: any }) {
    const { t, i18n } = useTranslation()

    const [transactions, setTransactions] = useState<any[]>([]);
    const [pageInfo, setPageInfo] = useState<any>(null);
    const [participants, setParticipants] = useState({});
    const [selectedParticipant, _setSelectedParticipant] = useState<any>(null);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [date, setDate] = useState(new Date());
    const navigate = useNavigate();

    const setSelectedParticipant = (participant: any) => {
        _setSelectedParticipant(participant)
        if (cookiesStorage.cookiesAccepted() == "true") {
            cookiesStorage.set('selectedParticipant', participant?.id)
        }
    }

    function getParticipantList() {
        const participantsMap = participants as any;
        return Object.values(participantsMap).sort((a: any, b: any) => {
            if (a.name > b.name) return 1;
            return -1;
        });
    }

    useEffect(() => {
        const unsubscribe2 = getDocs(collection(db, "groups", groupData.id, "participants")).then((querySnapshot) => {
            var data: { [key: string]: any } = {};
            querySnapshot.forEach((doc) => {
                data[doc.id] = { id: doc.id, ...doc.data() };
            });
            setParticipants(data);
            if (cookiesStorage.get('selectedParticipant') != null) {
                const id = cookiesStorage.get('selectedParticipant')
                if (id.length > 0 && data[id] != null) {
                    _setSelectedParticipant(data[id])
                }
            }
        }).catch((error) => {
            console.log(error);
        })
    }, []);

    useEffect(() => {
        const transactionsRef = collection(db, "groups", groupData.id, "transactions")
        const order = orderBy("date", "desc")
        var q = query(transactionsRef,
            where("date", ">=", new Date(date.getFullYear(), date.getMonth(), 1)),
            where("date", "<=", new Date(date.getFullYear(), date.getMonth() + 1, 0)),
            order)
        const unsubscribe = getDocs(q).then((querySnapshot) => {
            var list: any[] = [];
            querySnapshot.forEach((doc) => {
                list.push({
                    id: doc.id,
                    ...doc.data()
                });
            });
            setTransactions(list);
        }).catch((error) => {
            console.log(error);
        });
    }, [date])

    useEffect(() => {
        const pageData: any = {}
        var expense = 0
        var income = 0
        pageData.filteredTransactions = transactions.reduce((result, item) => {
            var data = { ...item }
            
            if(data.is_deleted) return result;
            
            function push() {
                if (data.type == "expense") {
                    expense += data.amount
                } else {
                    income += data.amount
                }
                result.push(data)
            }

            if (selectedParticipant == null) {
                push()
            } else if (item.participant_ids.includes(selectedParticipant.id)) {
                var data = { ...item }
                if (item.type == "expense") {
                    const multiplier = item.participant_counts[selectedParticipant.id]
                    data.count = multiplier
                    data.amount = item.add_amount * multiplier
                    data.add_amount = (multiplier > 1) ? item.add_amount : null
                }
                push()
            }
            return result
        }, [])
        pageData.totalExpense = expense
        pageData.totalIncome = income
        setPageInfo(pageData)
    }, [transactions, selectedParticipant])

    function balance() {
        return (selectedParticipant != null) ? selectedParticipant.balance : groupData.balance
    }

    function getCurrencyData() {
        return { 'currencyName': groupData.currency_name, 'currencyLocale': groupData.currency_locale }
    }

    function getTransactions() {
        if (pageInfo == null) return []

        var list: any[] = []
        var day = -1
        pageInfo.filteredTransactions.forEach((item: any) => {
            if (item.date.toDate().getDate() != day) {
                list.push(<li className='date-section' key={day}>{formatDayMonth(item.date.toDate())}</li>)
                day = item.date.toDate().getDate()
            }
            list.push(<li key={item.id}><Transaction item={item} currencyData={getCurrencyData()} is_group_view={(selectedParticipant == null)} /></li>)
        })
        return list
    }

    function changeDate(delta: number) {
        var newDate = new Date(date.getFullYear(), date.getMonth() + delta, 1)
        setDate(newDate)
    }

    return (
        <>
        <Helmet>
            <title>{groupData.name}</title>
        </Helmet>
        <ParticipantsContext.Provider value={participants}>
            <div className='main-group-container'>
                <div className='row'>
                    <div className='sub-row'>
                        <h1>{groupData.name}</h1>
                        <button  type="button" className="sub-btn" onClick={(e)=> navigate('i')}>
                            <img src={bell} alt="bell" /> <span className="sub-btn-txt">{t('subscribe_instructions')}</span> 
                        </button>
                    </div>
                    <div className='dropdown'>
                        <button className='dropdown-btn' onClick={(e) =>
                            setIsDropdownVisible(!isDropdownVisible)
                        }>
                            {(selectedParticipant != null) ? selectedParticipant.name : t('select_participant_dialog_title')}
                            <img src={arrow} />
                        </button>
                        <div className={'dropdown-options ' + (isDropdownVisible ? 'visible' : '')}>
                            <button className='dropdown-value' onClick={(e) => {
                                setSelectedParticipant(null)
                                setIsDropdownVisible(false)
                            }}>{t('select_participant_dialog_none')}</button>
                            {getParticipantList().filter((item: any) => !item.is_deleted).map((item: any) =>
                                <button className='dropdown-value' onClick={(e) => {
                                    setSelectedParticipant(item)
                                    setIsDropdownVisible(false)
                                }}>{item.name}</button>)
                            }
                        </div>
                    </div>
                </div>
                <div className='balance-txt'>
                    {t('generic_balance')}: <span className={'amount ' + (balance() > 0 ? 'positive' : 'negative')}>{formatAmount(balance(), getCurrencyData())}</span>
                </div>
                {(selectedParticipant != null) && <CookiesBanner onAccepted={() => {
                    if (selectedParticipant != null) {
                        cookiesStorage.set('selectedParticipant', selectedParticipant.id)
                    }
                }} />}
                <div className='month-selector-row'>
                    <div className='month-selector-container'>
                        <div className='month-selector'>
                            <button className="btn left" onClick={(e) => changeDate(-1)}><img src={arrow} /></button>
                            <div className='month-value'>{formatMonthYear(date)}</div>
                            <button className="btn right" onClick={(e) => changeDate(1)}><img src={arrow} /></button>
                        </div>
                        {
                            (pageInfo == null) ? null : (
                                <div className='finance'>
                                    <div className="bal-con income">
                                        {t('generic_income')}
                                        <div className="mobile-only">:&nbsp;</div>
                                        <div className="amount positive">+{formatAmount(pageInfo.totalIncome, getCurrencyData())}</div>
                                    </div>
                                    <div className="bal-con expense">
                                        {t('generic_expenses')}
                                        <div className="mobile-only">:&nbsp;</div>
                                        <div className="amount expense">-{formatAmount(pageInfo.totalExpense, getCurrencyData())}</div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
                <div className='transactions-list'>
                    <ul>
                        <li className='header'>
                            <div className='icon'></div>
                            <div className='name'>{t('generic_name')}</div>
                            <div className='date'>{t('generic_date')}</div>
                            <div className='amount'>{t('generic_amount')}</div>
                            <div className='status'>{t('generic_status')}</div>
                        </li>
                        {getTransactions()}
                    </ul>
                </div>
            </div>
        </ParticipantsContext.Provider>
        </>
    );
}

export default GroupData;