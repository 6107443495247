import React, { useState, useEffect } from 'react';
import './Group.css';
import NavBar from '../../features/nav_bar/NavBar';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { useParams } from 'react-router-dom';
import GroupData from './GroupData';
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Group() {
    const { t, i18n } = useTranslation()

    const { groupId } = useParams() as {groupId: string}
    const [groupData, setGroupData] = useState<any>({});
    
    useEffect(() => {
        const docRef = doc(db, "groups", groupId);
        getDoc(docRef).then((doc) => {
            const data = doc.data();
            if (data != undefined && data != null) {
                setGroupData({id:doc.id, 'loaded':true, ...data})
            } else {
                setGroupData({'error': t('generic_error_group_not_found')})
            }
        }).catch((error) => {
            console.log(error)
            setGroupData({'error': t('generic_error_group_not_found')})
        });
    }, []);

    return (
        <>
        <Helmet>
            <meta name="robots" content="noindex, nofollow" />
            <meta property="og:image" content="/gr_preview.jpg" />
            <meta property="og:title" content={window.location.pathname.split('/').pop()} />
        </Helmet>
        <header className="App-header">
                <NavBar is_group={true} />
            </header>
            {
            (groupData.error != undefined) ? (
                <div className="not-found-container">
                    <h1>{t('not_found_title')}</h1>
                    <p>{t('not_found_text')}</p>
                    <Link to="/">
                        <button className="go-back-button">{t('not_found_action')}</button>
                    </Link>
                </div>
              ) : (
                (groupData.loaded) && (<GroupData groupData={groupData} />)
              )
}
            </>
    );
}

export default Group;