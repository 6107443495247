import { useParams } from "react-router-dom";
import NavBar from "../../../features/nav_bar/NavBar";
import "./Instructions.css";
import { useState } from "react";
import Footer from "../../home/Footer";
import InstructionsSteps from "./InstructionsSteps";
import { useTranslation } from "react-i18next";

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  imageSrc: string;
};

const Instructions = () => {
  const { t, i18n } = useTranslation();
  
  const [isCopied, setIsCopied] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalImage, setModalImage] = useState("");

  const { groupId } = useParams<{ groupId: string }>();

  const GROUP_LINK = `grpwl.com/g/${groupId}`;

  const handleCopyClick = async () => {
    await navigator.clipboard.writeText(GROUP_LINK);
    setIsCopied(true);
  };

  const openModalWithImage = (src: string) => {
    setModalImage(src);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const Modal = ({ isOpen, onClose, imageSrc }: ModalProps) =>
    isOpen ? (
      <div onClick={onClose} className="modal">
        <img src={imageSrc} className="modal-image" />
        <button
          type="button"
          onClick={onClose}
          className="close-button button-common"
        >
          X
        </button>
      </div>
    ) : null;

  return (
    <>
      <Modal isOpen={modalIsOpen} onClose={closeModal} imageSrc={modalImage} />
      <header className="App-header">
        <NavBar is_group={true} />
      </header>
      <main className="container">
        <h1>{t('instructions_title')}</h1>
        <div className="flex-center group-link-container">
          <p>
            {t('instructions_group_link')}{" "}
            <a
              href={"https://" + GROUP_LINK}
              target="_blank"
              rel="noopener noreferrer"
            >
              {GROUP_LINK}
            </a>
          </p>
          <button className="copy-button" onClick={handleCopyClick}>
            {isCopied ? t('instructions_copied') : t('generic_actions_copy')}
          </button>
        </div>
        <p className="footer-text">
          {t('instructions_description_p1')} "<span className="highlight">{groupId}</span>
          " {t('instructions_description_p2')}
        </p>
        <p className="introduction-text">
          {t('instructions_steps_title')}
        </p>
        <ol>
          <InstructionsSteps
            groupId={groupId || ""}
            onClick={openModalWithImage}
          />
        </ol>
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  );
};

export default Instructions;
